<template>
  <Modal
    :value="value"
    title="养殖场绘制"
    @input="(val) => $emit('input', val)"
    fullscreen
  >
    <draw-map
      :backgroundPosition="backgroundPosition"
      v-if="value"
      ref="map"
      :mapPosition="mapPosition"
    ></draw-map>
    <p slot="footer" style="text-align: center">
      <Button type="primary" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import drawMap from "@/components/drawMap";
export default {
  name: "",
  components: {
    drawMap,
  },
  props: {
    backgroundPosition: [String, Array],
    value: {
      type: Boolean,
      default: false,
    },
    mapPosition: String,
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      let n = this.$refs.map.getCurrentFeatures();
      this.$emit("draw-finish", n);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-modal-body {
  padding: 0;
}
</style>